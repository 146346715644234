import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import { serviceInstance } from '../lib/axios';

export default function useUser() {
  const [user, setUser] = useState(null);
  const localStorageUser = JSON.parse(localStorage.getItem('user'));
  const localStorageSelectedProfile = JSON.parse(
    localStorage.getItem('selectedProfile'),
  );
  const [selectedProfile, setSelectedProfile] = useState(null);

  const { data, error, mutate } = useSWR('/user_info', async (key) => {
    if (!localStorageUser) {
      return null;
    }
    const response = await serviceInstance.post(
      key,
      {},
      {
        headers: {
          Authorization: localStorageUser.api_key,
        },
      },
    );
    return response.data.data;
  });

  useEffect(() => {
    if (data) {
      setUser(data);
      const profile = localStorageSelectedProfile || data?.[0];
      setSelectedProfile(profile);
      localStorage.setItem('selectedProfile', JSON.stringify(profile));
    }
  }, [data]);

  useEffect(() => {
    mutate();
  }, []);

  useEffect(() => {
    if (selectedProfile) {
      localStorage.setItem('selectedProfile', JSON.stringify(selectedProfile));
    }
  }, [selectedProfile]);

  return {
    user: {
      profiles: user,
      ...localStorageUser,
    },
    selectedProfile,
    setSelectedProfile,
    error,
  };
}
