/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import useSWR from 'swr';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { format } from 'date-fns';
import { FiChevronDown } from 'react-icons/fi';
import { RiCloseLine } from 'react-icons/ri';
import { useLocalStorage, useNetworkState } from '@uidotdev/usehooks';
import classNames from 'classnames';
import toast from 'react-hot-toast';
import useUser from '../../../hooks/useUser';
import { serviceInstance } from '../../../lib/axios';
import DashboardLayout from '../../../components/layout/dashboardLayout';
import Spinner from '../../../components/loading';

export default function Notifications() {
  const [value, setValue] = useLocalStorage('showNotification', 'false');
  const network = useNetworkState();
  const { user, setSelectedProfile, selectedProfile } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const handleSelect = (option) => {
    setSelectedProfile(option);
    setIsOpen(false);
  };
  const navigate = useNavigate();

  const [isSelected, setIsSelected] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const {
    data: notifications,
    mutate,
    isValidating,
  } = useSWR(
    user?.api_key && selectedProfile?.time_zone
      ? [
          `/notifications_read_all`,
          selectedProfile.id_cliente,
          selectedProfile.time_zone,
        ]
      : null,
    async ([url, id, timezone]) => {
      const response = await serviceInstance.post(
        url,
        {
          id_cliente: id,
          timezone,
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );
      return response.data.user_client_messages;
    },
  );

  const onSelect = (id) => {
    if (isSelected.includes(id)) {
      setIsSelected(isSelected.filter((selected) => selected !== id));
    } else {
      setIsSelected([...isSelected, id]);
    }
  };

  const onSelectedAll = () => {
    if (isSelected.length === notifications.length) {
      setIsSelected([]);
    } else {
      setIsSelected(
        notifications.map(
          (notification, index) => notification.id_notificacion,
        ),
      );
    }
  };

  useEffect(() => {
    if (value === 'true') {
      setTimeout(() => {
        mutate().then(() => {
          setValue('false');
        });
      }, 1000);
    }
  }, [value, mutate, setValue]);

  const deleteNotifications = async () => {
    try {
      await serviceInstance.post(
        '/notifications_delete',
        {
          id_cliente: selectedProfile.id_cliente,
          timezone: selectedProfile.time_zone,
          id_notificacion: isSelected,
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );
      toast.success('Notificaciones eliminadas con éxito');
    } catch (error) {
      toast.error('Error al eliminar las notificaciones');
    } finally {
      mutate();
      setIsSelected([]);
      setIsDeleting(false);
    }
  };

  return (
    <DashboardLayout>
      <div className="flex items-center justify-between mb-8">
        {!isDeleting ? (
          <>
            <div className="flex items-center space-x-2">
              <ReactSVG
                src={network.online ? 'svgs/online.svg' : 'svgs/offline.svg'}
              />
              <div className="relative inline-block w-full text-white">
                <button
                  onClick={toggleDropdown}
                  className="w-full flex justify-between items-center bg-gray-800 px-4 py-2 rounded-lg shadow-lg focus:outline-none"
                >
                  <span>{selectedProfile?.organizacion}</span>
                  <FiChevronDown className="ml-2" />
                </button>
                {isOpen && (
                  <div className="absolute left-0 w-full mt-2 bg-gray-700 rounded-lg shadow-lg z-50">
                    {user?.profiles.map((option) => (
                      <button
                        key={option.id_cliente}
                        onClick={() => handleSelect(option)}
                        className="w-full text-left px-4 py-2 hover:bg-gray-600 focus:outline-none"
                      >
                        {option.organizacion}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="w-6 h-6 text-white">
              <button onClick={() => setIsDeleting(!isDeleting)}>
                <FaRegTrashAlt size={24} />
              </button>
            </div>
          </>
        ) : (
          <div className="flex justify-between w-full items-center text-white bg-gray-800 p-4">
            <button onClick={() => setIsDeleting(!isDeleting)}>
              <RiCloseLine color="white" size={32} />
            </button>
            <div className="flex flex-row gap-4 items-center">
              <button className="text-sm" onClick={onSelectedAll}>
                Seleccionar todas
              </button>
              <button onClick={deleteNotifications}>
                <FaRegTrashAlt size={24} />
              </button>
            </div>
          </div>
        )}
      </div>

      <h1 className="text-3xl font-bold text-white mb-6">Notificaciones</h1>

      <div className="space-y-4 overflow-y-auto h-[70vh] text-white">
        {!isValidating ? (
          notifications?.map((notification) => (
            <div
              key={notification.id_notificacion}
              className="flex flex-row gap-5 items-center w-full"
            >
              {isDeleting && (
                <div className="flex items-center justify-center bg-gray-900">
                  <label className="relative flex items-center justify-center w-6 h-6 bg-gray-800 rounded-full cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      onClick={(e) => {
                        onSelect(notification.id_notificacion);
                      }}
                      checked={isSelected.includes(
                        notification.id_notificacion,
                      )}
                    />
                    <span className="size-full bg-green-300 rounded-full opacity-0 peer-checked:opacity-100 transition-opacity duration-300" />
                  </label>
                </div>
              )}
              <button
                className={classNames(
                  'bg-gray-800 rounded-2xl px-5 py-8 flex gap-6 items-center w-full justify-center',
                  {
                    'bg-gray-900': notification.leida,
                  },
                )}
                onClick={() =>
                  navigate(
                    `/dashboard/notifications/${notification.id_notificacion}?name=${notification.titulo}&icon=${notification.icono}`,
                  )
                }
              >
                <ReactSVG src={`/svgs/${notification.icono}`} alt="icono" />
                <div className="flex-1 w-[8rem] truncate">
                  <h3
                    className={classNames('font-semibold', {
                      'text-gray-400': notification.leida,
                    })}
                  >
                    {notification.titulo}
                  </h3>
                </div>
                <div className="text-right text-xs text-gray-400 w-14">
                  <p>{format(notification.created_at, 'dd/MM/yyyy hh:mm a')}</p>
                </div>
              </button>
            </div>
          ))
        ) : (
          <Spinner />
        )}
      </div>
    </DashboardLayout>
  );
}
