import React, { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { HiUser } from 'react-icons/hi2';
import {
  PiPasswordBold,
  PiEye,
  PiEyeClosed,
  PiArrowRight,
  PiArrowLeft,
} from 'react-icons/pi';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { generateToken, messaging } from '../lib/firebase';
import { serviceInstance } from '../lib/axios';
import useUser from '../hooks/useUser';

function Home() {
  const { user } = useUser();
  const navigate = useNavigate();
  const [token, setToken] = React.useState('');
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userid: '',
      password: '',
    },
  });

  useEffect(() => {
    if (user.api_key) {
      navigate('/dashboard');
    }
  }, [user]);

  const [showPassword, setShowPassword] = React.useState(false);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await generateToken();
      console.log('solicitando');
      console.log(token);
      setToken(token);
    };
    fetchToken();
  }, []);

  const onSubmit = async (data) => {
    try {
      const response = await serviceInstance.post(
        '/get_apikey_by_userpassword',
        data,
      );

      const userInfo = await serviceInstance.post(
        '/user_info',
        {},
        {
          headers: {
            Authorization: response.data.api_key,
          },
        },
      );

      localStorage.setItem('firebase-token', token);

      await serviceInstance.post(
        '/register_device',
        {
          id_cliente: userInfo.data.data[0].id_cliente,
          token,
          device_name: navigator.userAgent,
        },
        {
          headers: {
            Authorization: response.data.api_key,
          },
        },
      );

      localStorage.setItem('user', JSON.stringify(response.data));

      navigate('/dashboard');
    } catch (error) {
      toast.error('Usuario o contraseña incorrectos');
    }
  };
  return (
    <div className="bg-[#0C1116] min-h-screen flex flex-col">
      <button
        className="flex flex-row items-center gap-2 text-white ml-8 my-8"
        onClick={() => navigate('/')}
        tabIndex="0"
      >
        <PiArrowLeft className="size-6 text-4xl" />
        <p>Volver</p>
      </button>
      <div className="flex flex-col gap-4 h-full w-full items-center justify-center mt-auto mb-10">
        <ReactSVG src="illustrations/Login-cuate1.svg" />
      </div>
      <div className="flex flex-col gap-10 bg-[#131B23] border-red-100 w-full px-8 py-14 rounded-3xl">
        <div className="flex flex-col gap-4 w-full">
          <h1 className="text-4xl font-bold text-white">Ingresa los datos</h1>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <div className="flex items-center bg-white p-4 rounded-xl w-full">
                <HiUser className="text-gray-500 mr-3" />
                <input
                  {...register('userid', {
                    required: 'Este campo es requerido',
                    pattern: {
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                      message: 'Correo invalido',
                    },
                  })}
                  type="text"
                  placeholder="Usuario o correo"
                  className="bg-white flex-1 text-black text-left font-semibold text-lg outline-none"
                />
              </div>
              <ErrorMessage
                errors={errors}
                name="userid"
                render={({ message }) => (
                  <p className="text-red-500 text-xs">{message}</p>
                )}
              />
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center bg-white p-4 rounded-xl w-full">
                <PiPasswordBold className="text-gray-500 mr-3" />
                <input
                  {...register('password', {
                    required: 'Este campo es requerido',
                  })}
                  type={showPassword ? 'text' : 'password'}
                  className="bg-white flex-1 text-black text-left font-semibold text-lg outline-none"
                />
                {showPassword ? (
                  <PiEyeClosed
                    onClick={() => setShowPassword(false)}
                    className="text-gray-500"
                  />
                ) : (
                  <PiEye
                    onClick={() => setShowPassword(true)}
                    className="text-gray-500"
                  />
                )}
              </div>
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => (
                  <p className="text-red-500 text-xs">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <button className="flex flex-row justify-between items-center bg-[#449670] p-5 rounded-2xl text-white w-full text-left font-semibold text-lg">
              Continuar
              <PiArrowRight className="ml-2" />
            </button>
          </div>
          <div className="flex flex-row gap-4">
            <div className="flex items-center gap-2">
              <label
                htmlFor="rememberMe"
                className="flex flex-row gap-2 text-white text-xs items-center"
              >
                <input
                  name="rememberMe"
                  id="rememberMe"
                  type="checkbox"
                  className="size-4 ml"
                />
                <p>Recordar mi usuario</p>
              </label>
            </div>
            <button className="text-xs text-white underline bg-transparent border-none cursor-pointer">
              Olvidé mi usuario y/o contraseña
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Home;
