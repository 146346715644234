import React, { useState } from 'react';
import { PiCheckBold } from 'react-icons/pi';
import { RiCloseLine } from 'react-icons/ri';

import useSWR from 'swr';
import { FiChevronDown } from 'react-icons/fi';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { es } from 'date-fns/locale';
import { subMinutes, subHours, formatISO, formatDistance } from 'date-fns';
import { RxChevronLeft } from 'react-icons/rx';

import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import useUser from '../../../hooks/useUser';
import { serviceInstance } from '../../../lib/axios';
import DashboardLayout from '../../../components/layout/dashboardLayout';
import Spinner from '../../../components/loading';

const timesInvertal = [6, 12, 24, 72];

export default function IncidentesDetailsView() {
  const navigate = useNavigate();
  const { user, setSelectedProfile, selectedProfile } = useUser();
  const [selectedInterval, setSelectedInterval] = useState(72);
  const [openFilter, setOpenFilter] = useState(false);

  const [searchParams, _] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();

  const calculateIntervalStart = (end) => {
    const now = new Date();
    let intervalStart;
    if (selectedInterval === 30) {
      intervalStart = subMinutes(now, selectedInterval);
    } else {
      intervalStart = subHours(now, selectedInterval);
    }
    return formatISO(intervalStart);
  };

  const {
    data: incidents,
    mutate,
    isValidating,
  } = useSWR(
    user?.api_key && selectedProfile?.time_zone ? `/incidents_interval` : null,
    async (key) => {
      const response = await serviceInstance.post(
        key,
        {
          id_test: id,
          id_cliente: selectedProfile?.id_cliente,
          interval_end: searchParams.get('interval_end'),
          interval_start: searchParams.get('interval_start'),
          timezone: 'America/Santiago',
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );
      return response.data.data;
    },
  );

  const handleSelectFilter = (option) => {
    setSelectedInterval(option);
    setOpenFilter(false);
    mutate();
  };

  return (
    <DashboardLayout>
      <div>
        <div className="flex items-center justify-between mb-8">
          <div className="flex flex-row gap-2">
            <button
              className="flex flex-row items-center gap-2 text-white"
              onClick={() => navigate(-1)}
              tabIndex="0"
            >
              <RxChevronLeft className="size-6 text-4xl" />
            </button>
            <div className="text-white">
              <h1 className="text-3xl font-bold">{incidents?.[0]?.nombre}</h1>
              <span>Incidentes</span>
            </div>
          </div>
          <div className="flex items-center">
            <div className="min-h-12 min-w-12 max-w-12 max-h-12 bg-gray-700 rounded-full flex items-center justify-center">
              <ReactSVG
                src={`/svgs/${searchParams.get('displayLogo')}.svg`}
                alt="icono"
              />
            </div>
          </div>
        </div>

        <h2 className="text-3xl font-bold text-white">Incidentes</h2>
        <div className="flex flex-row justify-end items-center">
          <div className="relative inline-block text-white">
            <button
              onClick={() => setOpenFilter(!openFilter)}
              className="w-full flex justify-between items-center border bg-gray-800 px-4 py-2 rounded-lg shadow-lg focus:outline-none"
            >
              <span>
                {' '}
                {selectedInterval !== 30
                  ? `${selectedInterval} horas`
                  : '30 minutos'}
              </span>
              <FiChevronDown className="ml-2" />
            </button>
            {openFilter && (
              <div className="absolute z-50 left-0 w-full mt-2 bg-gray-700 rounded-lg shadow-lg">
                {timesInvertal?.map((option) => (
                  <button
                    key={option}
                    onClick={() => handleSelectFilter(option)}
                    className="w-full text-left px-4 py-2 hover:bg-gray-600 focus:outline-none"
                  >
                    {option !== 30 ? `${option} horas` : '30 minutos'}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="space-y-4 h-[62vh] bg-[#131B23] overflow-y-auto p-2 mt-8">
          {!isValidating ? (
            incidents?.map((incident, index) => (
              <button
                id={incident.test}
                onClick={() =>
                  navigate(
                    `/dashboard/incidents/${incident.id_test}/breakdown?interval_start=${calculateIntervalStart()}&interval_end=${formatISO(new Date())}&name=${incidents?.[0].nombre}&displayLogo=${searchParams.get('displayLogo')}`,
                  )
                }
                className={classNames(
                  'flex flex-row justify-between gap-4 p-4 rounded-xl text-white items-center w-full bg-gray-800 border-l-4 border-blue-600',
                  {
                    'border-l-4 border-red-600': !incident.fin,
                  },
                )}
              >
                <div className="flex flex-col gap-y-2 text-start">
                  <h2 className="text-2xl font-bold text-white">
                    {incident.id_incidente}
                  </h2>
                  <p>
                    <span className="font-semibold">Inicio:</span>{' '}
                    {incident.inicio}
                  </p>
                  <p>
                    <span className="font-semibold">Fin:</span> {incident.fin}
                  </p>
                  <p>
                    <span className="font-semibold">Duración</span>{' '}
                    {formatDistance(
                      new Date(incident.inicio),
                      new Date(incident.fin ?? new Date()),
                      {
                        addSuffix: true,
                        locale: es,
                      },
                    ).replace('hace', '')}
                  </p>
                </div>
                <div className="flex flex-row gap-2 text-center items-center justify-center">
                  {incident.email_user_ack && (
                    <div className="flex flex-row gap-2">
                      <p>Aceptado: </p>
                      <RiCloseLine className="text-red-500" />
                    </div>
                  )}
                </div>
              </button>
            ))
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}
