import React from 'react';
import { ReactSVG } from 'react-svg';
import { HiUser } from 'react-icons/hi2';
import { PiArrowRight, PiArrowLeft } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

function RecoverPasswordEmail() {
  const navigate = useNavigate();
  const [isCompleted, setIsCompleted] = React.useState(false);

  if (isCompleted) {
    return (
      <div className="bg-[#0C1116] min-h-screen flex flex-col">
        <div className="flex flex-col gap-4 h-full w-full items-center justify-center mt-auto mb-10">
          <ReactSVG src="illustrations/Recover-cuate1.svg" />
        </div>
        <div className="flex flex-col gap-10 border-red-100 w-full px-8 py-14 rounded-3xl">
          <div className="flex flex-col gap-4 w-full">
            <h1 className="text-4xl font-bold text-white">
              Recupera tu contraseña
            </h1>
            <p className="text-white font-medium">
              Revisa tu correo electrónico donde recibirás tu nueva contraseña.
            </p>
          </div>

          <div className="flex flex-col gap-6">
            <div className="flex flex-row gap-4">
              <button
                className="flex flex-row justify-between items-center bg-[#449670] p-5 rounded-2xl text-white w-full text-left font-semibold text-lg"
                onClick={() => navigate('/login')}
              >
                Ir al inicio
                <PiArrowRight className="ml-2" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-[#0C1116] min-h-screen flex flex-col">
      <button
        className="flex flex-row items-center gap-2 text-white ml-8 my-8"
        onClick={() => navigate(-1)}
        tabIndex="0"
      >
        <PiArrowLeft className="size-6 text-4xl" />
        <p>Volver</p>
      </button>
      <div className="flex flex-col gap-4 h-full w-full items-center justify-center mt-auto mb-10">
        <ReactSVG src="illustrations/Login-cuate1.svg" />
      </div>
      <div className="flex flex-col gap-10 bg-[#131B23] border-red-100 w-full px-8 py-14 rounded-3xl">
        <div className="flex flex-col gap-4 w-full">
          <h1 className="text-4xl font-bold text-white">Ingresa los datos</h1>
          <p className="text-white font-medium">
            Ingresa tu correo electrónico para recuperar tu contraseña. Te
            enviaremos un enlace con instrucciones para restablecerla.
          </p>
        </div>

        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <div className="flex items-center bg-white p-4 rounded-xl w-full">
              <HiUser className="text-gray-500 mr-3" />
              <input
                type="text"
                placeholder="Ingresa tu correo o dispositivo"
                className="bg-white flex-1 text-black text-left font-semibold text-lg outline-none"
              />
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <button
              className="flex flex-row justify-between items-center bg-[#449670] p-5 rounded-2xl text-white w-full text-left font-semibold text-lg"
              onClick={() => setIsCompleted(true)}
            >
              Recuperar cuenta
              <PiArrowRight className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecoverPasswordEmail;
