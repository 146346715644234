import React, { Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaSitemap } from 'react-icons/fa6';
import { PiHouse, PiWarningDuotone } from 'react-icons/pi';
import { LuMessageSquare } from 'react-icons/lu';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

export default function DashboardLayout({ children, className }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  return (
    <div className={twMerge('bg-[#0C1116] h-[100vh] pt-8 px-5', className)}>
      <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
      <div className="fixed bottom-3 left-[5px] w-[98%] bg-gray-800 py-1 flex justify-around rounded-2xl border-t border-b border-gray-400">
        <div className="flex flex-row gap-4">
          <div className="group">
            <button
              onClick={() => navigate('/dashboard')}
              className={classNames(
                'flex items-end justify-center text-center pt-2 w-full text-gray-400',
                {
                  'text-green-500 bg-white/5': pathname === '/dashboard',
                },
              )}
            >
              <span className="block px-1 pt-1 pb-1">
                <PiHouse className="size-8 mx-auto pt-1 mb-1 block" />
                <span className="block text-xs pb-2">Estado Actual</span>
                <span
                  className={classNames(
                    'block w-full mx-auto h-1 rounded-full',
                    {
                      'bg-green-500': pathname === '/dashboard',
                    },
                  )}
                />
              </span>
            </button>
          </div>
          <div className="group">
            <button
              onClick={() => navigate('/dashboard/evolution')}
              className={classNames(
                'flex items-end justify-center text-center pt-2 w-full text-gray-400',
                {
                  'text-green-500 bg-white/5': pathname.includes('/evolution'),
                },
              )}
            >
              <span className="block px-1 pt-1 pb-1">
                <FaSitemap className="size-8 mx-auto pt-1 mb-1 block" />
                <span className="block text-xs pb-2">Evolución</span>
                <span
                  className={classNames(
                    'block w-full mx-auto h-1 rounded-full',
                    {
                      'bg-green-500': pathname.includes('/evolution'),
                    },
                  )}
                />
              </span>
            </button>
          </div>
          <div className="group">
            <button
              onClick={() => navigate('/dashboard/incidents')}
              className={classNames(
                'flex items-end justify-center text-center pt-2 w-full text-gray-400',
                {
                  'text-green-500 bg-white/5': pathname.includes('/incidents'),
                },
              )}
            >
              <span className="block px-1 pt-1 pb-1">
                <PiWarningDuotone className="size-8 mx-auto pt-1 mb-1 block" />
                <span className="block text-xs pb-2">Incidentes</span>
                <span className="block w-full mx-auto h-1 group-hover:bg-green-500 rounded-full" />
              </span>
            </button>
          </div>
          <div className="group">
            <button
              onClick={() => navigate('/dashboard/notifications')}
              className={classNames(
                'flex items-end justify-center text-center pt-2 w-full text-gray-400',
                {
                  'text-green-500 bg-white/5':
                    pathname.includes('/notifications'),
                },
              )}
            >
              <span className="block px-1 pt-1 pb-1">
                <LuMessageSquare className="size-8 mx-auto pt-1 mb-1 block" />
                <span className="block text-xs pb-2">Notificaciones</span>
                <span
                  className={classNames(
                    'block w-full mx-auto h-1 rounded-full',
                    {
                      'bg-green-500': pathname.includes('/notifications'),
                    },
                  )}
                />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
