import React, { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { HiMiniQrCode } from 'react-icons/hi2';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useUser from '../hooks/useUser';

function Home() {
  const { user } = useUser();
  const [searchParams, _] = useSearchParams();

  if (searchParams.get('api_key')) {
    localStorage.setItem(
      'user',
      JSON.stringify({
        api_key: searchParams.get('api_key'),
      }),
    );
  }

  const navigate = useNavigate();

  useEffect(() => {
    if (user.api_key) {
      navigate('/dashboard');
    }
  }, [user]);
  return (
    <div className="bg-[#0C1116] min-h-screen flex flex-col">
      <div className="flex flex-col gap-4 h-full w-full items-center justify-center mt-auto mb-10">
        <img src="illustrations/Revenue-cuate1.svg" alt="icon" />
      </div>
      <div className="flex flex-col gap-10 bg-[#131B23] border-red-100 w-full px-8 py-14 rounded-3xl">
        <div className="flex flex-col gap-4 w-10/12">
          <h1 className="text-4xl font-bold text-white">
            Hola somos realUptîme
          </h1>
          <p className="text-white font-medium">
            Una herramienta para ayudarte a monitorear tus servicios de manera
            rápida y eficiente. ¡Comencemos!
          </p>
        </div>

        <div className="flex flex-col gap-6">
          <div className="flex flex-row gap-4">
            <button
              className="bg-[#449670] p-5 rounded-2xl text-white w-[80%] text-left"
              onClick={() => navigate('/login')}
            >
              Ingresar
            </button>
            <button
              className="bg-[#449670] p-5 rounded-2xl text-white"
              onClick={() => navigate('/scan-qr')}
            >
              <HiMiniQrCode />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
