import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { FiChevronDown } from 'react-icons/fi';
import { useNetworkState } from '@uidotdev/usehooks';
import { serviceInstance } from '../../lib/axios';
import useUser from '../../hooks/useUser';
import DashboardLayout from '../../components/layout/dashboardLayout';
import Spinner from '../../components/loading';

const Dashboard = () => {
  const network = useNetworkState();
  const navigate = useNavigate();
  const { user, setSelectedProfile, selectedProfile } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    setSelectedProfile(option);
    setIsOpen(false);
  };

  const { data: services, isValidating } = useSWR(
    user?.api_key && selectedProfile?.time_zone
      ? [
          '/services_status',
          selectedProfile.id_cliente,
          selectedProfile.time_zone,
        ]
      : null,
    async ([url, id, timezone]) => {
      const response = await serviceInstance.post(
        url,
        {
          id_cliente: id,
          timezone,
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );
      return response.data.data;
    },
  );

  const filteredServices = services?.filter((servicio) =>
    servicio.nombre.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <DashboardLayout>
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center space-x-2">
          <ReactSVG
            src={network.online ? 'svgs/online.svg' : 'svgs/offline.svg'}
          />
          <div className="relative inline-block w-full text-white">
            <button
              onClick={toggleDropdown}
              className="w-full flex justify-between items-center bg-gray-800 px-4 py-2 rounded-lg shadow-lg focus:outline-none"
            >
              <span>{selectedProfile?.organizacion}</span>
              <FiChevronDown className="ml-2" />
            </button>
            {isOpen && (
              <div className="absolute left-0 w-full mt-2 bg-gray-700 rounded-lg shadow-lg z-50">
                {user?.profiles.map((option) => (
                  <button
                    key={option.id_cliente}
                    onClick={() => handleSelect(option)}
                    className="w-full text-left px-4 py-2 hover:bg-gray-600 focus:outline-none"
                  >
                    {option.organizacion}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="w-6 h-6 text-white">
          {/* Placeholder para el ícono de filtro */}
        </div>
      </div>

      <h1 className="text-white text-3xl font-bold mb-6">Estado Actual</h1>

      <div className="flex flex-row gap-4 mb-6">
        <input
          type="text"
          className="w-full p-3 rounded-2xl bg-transparent border border-gray-800 text-white placeholder-gray-500 focus:outline-none"
          placeholder="Buscar"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="space-y-4 h-[60vh] overflow-y-auto">
        {filteredServices?.length && !isValidating ? (
          filteredServices.map((servicio) => (
            <div
              key={servicio.id_test}
              role="button"
              tabIndex={0}
              onClick={() =>
                navigate(
                  servicio.monitor_family === 1
                    ? `/dashboard/status/${servicio.id_test}?name=${servicio.nombre}&monitor_family=${servicio.monitor_family}&status_code=${servicio.status_code}&en_incidente=${servicio.en_incidente}${servicio.en_mantenimiento ? '&en_mantenimiento=true' : ''}`
                    : `/dashboard/analytics-details/${servicio.id_test}?name=${servicio.nombre}&monitor_family=${servicio.monitor_family}&status_code=${servicio.status_code}&en_incidente=${servicio.en_incidente}${servicio.en_mantenimiento ? '&en_mantenimiento=true' : ''}`,
                )
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  navigate(
                    servicio.monitor_family === 1
                      ? `/dashboard/status/${servicio.id_test}?name=${servicio.nombre}&monitor_family=${servicio.monitor_family}&status_code=${servicio.status_code}&en_incidente=${servicio.en_incidente}${servicio.en_mantenimiento ? '&en_mantenimiento=true' : ''}`
                      : `/dashboard/analytics-details/${servicio.id_test}?name=${servicio.nombre}&monitor_family=${servicio.monitor_family}&status_code=${servicio.status_code}&en_incidente=${servicio.en_incidente}${servicio.en_mantenimiento ? '&en_mantenimiento=true' : ''}`,
                  );
                }
              }}
              className={classNames(
                'bg-gray-800 p-4 rounded-2xl border-l-4 flex items-center justify-between',
                {
                  'border-green-600': servicio.status_code === 0,
                  'border-yellow-500': servicio.status_code === 1,
                  'border-red-500':
                    servicio.status_code === 2 && servicio.en_incidente,
                  'border-orange-400':
                    servicio.status_code === 2 && !servicio.en_incidente,
                },
              )}
            >
              <div className="relative flex items-center space-x-4">
                <div
                  className={classNames(
                    'p-2 min-h-12 min-w-12 max-w-12 max-h-12',
                    {
                      'bg-green-600': servicio.status_code === 0,
                      'bg-yellow-400': servicio.status_code === 1,
                      'bg-red-600':
                        servicio.status_code === 2 && servicio.en_incidente,
                      'bg-orange-400':
                        servicio.status_code === 2 && !servicio.en_incidente,
                    },
                  )}
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    width: '48px',
                    height: '48px',
                    clipPath:
                      'polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)',
                  }}
                >
                  <ReactSVG
                    src={`svgs/${servicio.monitor_family === 1 ? 'robotBlack' : 'privalyticsBlack'}.svg`}
                  />
                  {servicio.en_mantenimiento && (
                    <div className="absolute top-0 left-0">
                      <ReactSVG src="svgs/mantenimiento.svg" />
                    </div>
                  )}
                </div>
                <div>
                  <p className="text-white font-bold">{servicio.nombre}</p>
                </div>
              </div>
              <div>
                {/* Placeholder para el ícono de flecha */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-6 h-6 text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>
            </div>
          ))
        ) : (
          <Spinner />
        )}
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
