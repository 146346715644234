import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import { serviceInstance } from '../lib/axios';
import useUser from './useUser';

export default function useLocationConfig() {
  const { user, selectedProfile } = useUser();
  const localStorageUser = JSON.parse(localStorage.getItem('user'));
  const { data } = useSWR(
    user?.api_key && selectedProfile?.time_zone ? '/locations_config' : null,
    async (key) => {
      if (!localStorageUser) {
        return null;
      }
      const response = await serviceInstance.post(
        key,
        {
          id_cliente: selectedProfile.id_cliente,
        },
        {
          headers: {
            Authorization: localStorageUser.api_key,
          },
        },
      );
      return response.data.data;
    },
  );

  return data;
}
