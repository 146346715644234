import axios from 'axios';

const getTokenData = (bearerToken) => {
  if (!bearerToken) return null;

  const token = bearerToken.split('Bearer ')[1];
  return token;
};

const getAuthHeaders = (token, contentType) => {
  const headers = {
    'Content-Type': contentType,
    Authorization: '',
  };

  if (token) headers.Authorization = `Bearer ${token}`;

  return headers;
};
const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const commonConfig = {
  baseURL: 'https://api.realuptime.net/api',
  headers: commonHeaders,
};

const serviceInstance = axios.create(commonConfig);

const authInstance = axios.create(commonConfig);
authInstance.interceptors.request.use((config) => {
  return new Promise((resolve, reject) => {
    if (!config.headers.Authorization)
      reject(new Error('Authorization header is missing'));

    resolve({ ...config, headers: config.headers });
  });
});

const authInstanceFormData = axios.create(commonConfig);
authInstanceFormData.interceptors.request.use((config) => {
  return new Promise((resolve, reject) => {
    if (!config.headers.Authorization)
      reject(new Error('Authorization header is missing'));

    const token = getTokenData(config.headers.Authorization);
    const authHeaders = getAuthHeaders(
      token,
      'multipart/form-data; charset=utf-8',
    );

    resolve({ ...config, headers: authHeaders });
  });
});

export { serviceInstance, authInstance, authInstanceFormData };
