import React from 'react';
import { PiArrowRight } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import Welcome from '../components/onboarding/Welcome';
import Notifications from '../components/onboarding/Notifications';
import Finish from '../components/onboarding/Finish';

function Onboarding() {
  const [step, setStep] = React.useState(0);
  const navigate = useNavigate();

  let StepForm = null;

  if (step === 0) {
    StepForm = Welcome;
  }

  if (step === 1) {
    StepForm = Notifications;
  }

  if (step === 2) {
    StepForm = Finish;
  }

  return (
    <div>
      <StepForm>
        <div className="flex flex-col gap-6">
          <div className="flex flex-row gap-4">
            <button
              className="bg-transparent p-5 rounded-2xl text-white w-[80%] text-left"
              onClick={() => navigate('/login')}
            >
              Omitir
            </button>
            <button
              className="bg-[#449670] p-5 rounded-2xl text-white"
              onClick={() => {
                setStep(step + 1);
                if (step === 2) {
                  navigate('/dashboard');
                }
              }}
            >
              <PiArrowRight />
            </button>
          </div>
        </div>
      </StepForm>
    </div>
  );
}

export default Onboarding;
