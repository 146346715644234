import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import QRPage from './pages/QR';
import Home from './pages/Home';
import Login from './pages/Login';
import RecoverPasswordEmail from './pages/RecoverPasswordEmail';
import ScanQR from './pages/scanQR/ScanQR';
import Onboarding from './pages/Onboarding';
import Dashboard from './pages/dashboard';
import Evolution from './pages/dashboard/evolution/evolution';
import EvolutionDetails from './pages/dashboard/evolution/details';
import Incidents from './pages/dashboard/incidents/incidents';
import Notifications from './pages/dashboard/notifications/notifications';
import ProtectedRoute from './components/ProtectedRoutes';
import StatusDetails from './pages/dashboard/status/details';
import IncidentStatusOverview from './pages/dashboard/status/incident-details';
import AnalyticsDetails from './pages/dashboard/status/analytics-details';
import IncidentBreakdown from './pages/dashboard/status/incident-breakdown';
import IncidentesDetailsView from './pages/dashboard/incidents/details';
import IncidentesBreakdownView from './pages/dashboard/incidents/breakdown';
import NotificationDetails from './pages/dashboard/notifications/details';

function App() {
  return (
    <div>
      <Toaster />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/qr" element={<QRPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/recover-password" element={<RecoverPasswordEmail />} />
          <Route path="/scan-qr" element={<ScanQR />} />
          <Route path="/onboarding" element={<Onboarding />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/status/:id"
            element={
              <ProtectedRoute>
                <StatusDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/status/:id/incident-details"
            element={
              <ProtectedRoute>
                <IncidentStatusOverview />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/status/:id/incident-details/incident-breakdown"
            element={
              <ProtectedRoute>
                <IncidentBreakdown />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/analytics-details/:id"
            element={
              <ProtectedRoute>
                <AnalyticsDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/evolution"
            element={
              <ProtectedRoute>
                <Evolution />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/evolution/details/:id"
            element={
              <ProtectedRoute>
                <EvolutionDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/incidents"
            element={
              <ProtectedRoute>
                <Incidents />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/incidents/:id"
            element={
              <ProtectedRoute>
                <IncidentesDetailsView />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/incidents/:id/breakdown"
            element={
              <ProtectedRoute>
                <IncidentesBreakdownView />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/notifications"
            element={
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/notifications/:id"
            element={
              <ProtectedRoute>
                <NotificationDetails />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
