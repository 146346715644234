import React, { useState, useEffect, useMemo } from 'react';
import useSWR from 'swr';
import { FiChevronDown } from 'react-icons/fi';
import { subMinutes, subHours, formatISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { toZonedTime } from 'date-fns-tz';
import { useNetworkState } from '@uidotdev/usehooks';
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';
import Spinner from '../../../components/loading';
import useUser from '../../../hooks/useUser';
import { serviceInstance } from '../../../lib/axios';
import DashboardLayout from '../../../components/layout/dashboardLayout';

const timesInvertal = [30, 6, 12, 24, 72];

const Evolution = () => {
  const navigate = useNavigate();
  const network = useNetworkState();
  const [selectedInterval, setSelectedInterval] = useState(30);
  const { user, selectedProfile, setSelectedProfile } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const handleSelectProfile = (option) => {
    setSelectedProfile(option);
    setIsOpen(false);
  };

  const calculateIntervalStart = (end) => {
    const now = new Date();
    let intervalStart;
    if (selectedInterval === 30) {
      intervalStart = subMinutes(now, selectedInterval);
    } else {
      intervalStart = subHours(now, selectedInterval);
    }

    return formatISO(intervalStart);
  };

  const {
    data: evolutionServices,
    mutate,
    isValidating,
  } = useSWR(
    user?.api_key && selectedProfile?.time_zone
      ? [
          '/services_evolution',
          selectedProfile.id_cliente,
          selectedProfile.time_zone,
        ]
      : null,
    async ([url, id, timezone]) => {
      const response = await serviceInstance.post(
        url,
        {
          id_cliente: id,
          timezone,
          interval_start: calculateIntervalStart(),
          interval_end: formatISO(new Date()),
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );
      return response.data.timevis_data;
    },
    {
      refreshInterval: 1000 * 30,
    },
  );

  const { data: servicesConfig } = useSWR(
    user?.api_key && selectedProfile?.time_zone
      ? [
          '/services_config',
          selectedProfile.id_cliente,
          selectedProfile.time_zone,
        ]
      : null,
    async ([url, id, timezone]) => {
      const response = await serviceInstance.post(
        url,
        {
          id_cliente: id,
          timezone,
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );
      return response.data.data;
    },
    {
      refreshInterval: 1000 * 30,
    },
  );

  const handleSelectFilter = (option) => {
    setSelectedInterval(option);
    localStorage.setItem('selectedInterval', option);
    setOpenFilter(false);
    mutate();
  };

  useEffect(() => {
    const interval = localStorage.getItem('selectedInterval');
    if (interval) {
      setSelectedInterval(parseInt(interval, 10));
    }
  }, [selectedProfile]);

  const getStatusColor = (className) => {
    switch (className) {
      case 'ok':
        return 'bg-green-500';
      case 'warning':
        return 'bg-yellow-400';
      case 'critical':
        return 'bg-red-600';
      case 'down':
        return 'bg-orange-500';
      default:
        return 'bg-gray-300';
    }
  };

  const processTimelineData = (items) => {
    const groupedData = {};

    items.forEach((item) => {
      if (!groupedData[item.group]) {
        groupedData[item.group] = [];
      }
      groupedData[item.group].push(item);
    });

    return groupedData;
  };

  const calculateSegmentWidth = (start, end, totalStart, totalEnd) => {
    const startTime = new Date(start).getTime();
    const endTime = new Date(end).getTime();
    const totalStartTime = new Date(totalStart).getTime();
    const totalEndTime = new Date(totalEnd).getTime();
    const totalDuration = totalEndTime - totalStartTime;

    return ((endTime - startTime) / totalDuration) * 100;
  };

  // Generate time labels based on timeRange
  const generateTimeLabels = () => {
    if (!selectedProfile.time_zone) {
      return null;
    }
    const labels = [];
    const now = new Date(toZonedTime(new Date(), selectedProfile.time_zone));
    let endTime;

    if (selectedInterval === 30) {
      endTime = subMinutes(now, 0);
    } else {
      endTime = subHours(now, 0);
    }

    const step = selectedInterval / 6;
    const endHour = endTime.getHours();

    for (let i = 6; i >= 0; i -= 1) {
      const hour = (endHour - i * step + 24) % 24;
      labels.push(
        <div key={i} className="text-xs text-white">
          {String(Math.abs(hour)).padStart(2, '0')}:00
        </div>,
      );
    }
    return labels;
  };

  const groupedData = useMemo(
    () =>
      evolutionServices ? processTimelineData(evolutionServices.items) : {},
    [evolutionServices, selectedInterval],
  );

  useEffect(() => {
    mutate();
  }, [selectedInterval]);

  return (
    <DashboardLayout>
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center space-x-2">
          <ReactSVG
            src={network.online ? 'svgs/online.svg' : 'svgs/offline.svg'}
          />
          <div className="relative inline-block w-full text-white">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="w-full flex justify-between items-center bg-gray-800 px-4 py-2 rounded-lg shadow-lg focus:outline-none"
            >
              <span>{selectedProfile?.organizacion}</span>
              <FiChevronDown className="ml-2" />
            </button>
            {isOpen && (
              <div className="absolute left-0 w-full mt-2 bg-gray-700 rounded-lg shadow-lg z-50">
                {user?.profiles.map((option) => (
                  <button
                    key={option.id_cliente}
                    onClick={() => handleSelectProfile(option)}
                    className="w-full text-left px-4 py-2 hover:bg-gray-600 focus:outline-none"
                  >
                    {option.organizacion}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="w-6 h-6 text-white">
          {/* Placeholder para el ícono de filtro */}
        </div>
      </div>

      <h1 className="text-white text-3xl font-bold mb-6">
        Evolución Servicios
      </h1>

      {/* Timeline */}
      <div className="flex flex-row justify-end items-center">
        <div className="relative inline-block text-white">
          <button
            onClick={() => setOpenFilter(!openFilter)}
            className="w-full flex justify-between items-center border bg-gray-800 px-4 py-2 rounded-lg shadow-lg focus:outline-none"
          >
            <span>
              {' '}
              {selectedInterval !== 30
                ? `${selectedInterval} horas`
                : '30 minutos'}
            </span>
            <FiChevronDown className="ml-2" />
          </button>
          {openFilter && (
            <div className="absolute z-50 left-0 w-full mt-2 bg-gray-700 rounded-lg shadow-lg">
              {timesInvertal?.map((option) => (
                <button
                  key={option}
                  onClick={() => handleSelectFilter(option)}
                  className="w-full text-left px-4 py-2 hover:bg-gray-600 focus:outline-none"
                >
                  {option !== 30 ? `${option} horas` : '30 minutos'}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="space-y-4 h-[62vh] bg-[#131B23] overflow-y-auto p-4 mt-6">
        <div className="space-y-4">
          {!isValidating ? (
            Object.entries(groupedData).map(([groupId, items]) => {
              const sortedItems = items.sort(
                (a, b) => new Date(a.start) - new Date(b.start),
              );

              const groupLabel = servicesConfig?.find(
                (config) => config.id_test === parseInt(groupId, 10),
              )?.nombre;

              const firstItem = sortedItems[0];
              const lastItem = sortedItems[sortedItems.length - 1];

              return (
                <button
                  key={groupId}
                  onClick={() =>
                    navigate(
                      `/dashboard/evolution/details/${groupId}?interval_start=${calculateIntervalStart()}&interval_end=${formatISO(new Date())}&name=${groupLabel}`,
                    )
                  }
                  className="p-4 bg-gray-800 rounded-lg border border-gray-700 w-full text-start"
                >
                  <div className="mb-2 text-white font-bold">{groupLabel}</div>
                  <div className="relative w-full">
                    <div
                      className={classNames(
                        'flex flex-row h-6 rounded-md overflow-hidden relative',
                      )}
                    >
                      {sortedItems.map((item) => {
                        const fillOutBar = item.classname === 'down';

                        const withOverlay = item.classname === 'maintenance';

                        if (fillOutBar) {
                          return (
                            <div
                              key={item.start}
                              className={classNames(
                                `${getStatusColor('critical')} h-full absolute`,
                              )}
                              style={{
                                width: `100%`,
                              }}
                            />
                          );
                        }

                        if (withOverlay) {
                          return (
                            <div
                              key={item.start}
                              className={classNames(
                                `${getStatusColor(item.classname)} h-full z-40 absolute`,
                              )}
                              style={{
                                width: `${calculateSegmentWidth(item.start, item.end, firstItem.start, lastItem.end)}%`,
                                left: `${calculateSegmentWidth(firstItem.start, item.start, firstItem.start, lastItem.end)}%`,
                                opacity: 0.4,
                                backgroundImage: `linear-gradient(-45deg, rgba(128, 128, 128, 1) 25%, transparent 25%, transparent 50%, rgba(128, 128, 128, 1) 50%, rgba(128, 128, 128, 0.5) 75%, transparent 75%, transparent)`,
                                backgroundSize: '2rem 2rem',
                              }}
                            />
                          );
                        }

                        return (
                          <div
                            key={item.start}
                            className={classNames(
                              `${getStatusColor(item.classname)} h-full`,
                            )}
                            style={{
                              width: `${calculateSegmentWidth(item.start, item.end, firstItem.start, lastItem.end)}%`,
                            }}
                          />
                        );
                      })}
                    </div>
                    <div className="flex justify-between mt-1 px-1">
                      {generateTimeLabels()}
                    </div>
                  </div>
                </button>
              );
            })
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Evolution;
