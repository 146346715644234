import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { HiUser } from 'react-icons/hi2';
import { PiArrowRight, PiArrowLeft } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';

function Notifications({ children }) {
  const navigate = useNavigate();

  return (
    <div className="bg-[#0C1116] min-h-screen flex flex-col">
      <button
        className="flex flex-row items-center gap-2 text-white ml-8 my-8"
        onClick={() => navigate(-1)}
        tabIndex="0"
      >
        <PiArrowLeft className="size-6 text-4xl" />
        <p>Volver</p>
      </button>
      <div className="flex flex-col w-full items-center -mb-16 -mt-8">
        <ReactSVG src="illustrations/Push-cuate1.svg" />
      </div>
      <div className="flex flex-col gap-4 bg-url-1 bg-top bg-no-repeat border-red-100 w-full px-8 pb-8">
        <div className="flex flex-col gap-4 w-full pt-52">
          <h1 className="text-4xl font-bold text-white">
            Mantente al día con notificaciones
          </h1>
          <p className="text-white font-medium">
            Recibe alertas instantáneas sobre incidentes o cambios en tus
            servicios. Nunca te pierdas una actualización importante.
          </p>
        </div>

        <div className="flex flex-row gap-2 py-4 rounded relative overflow-hidden">
          <div className="size-2 rounded-full inset-0 bg-gray-700 animate-shimmer" />
          <div className="w-12 h-2 rounded-full bg-[#449670] animate-shimmer" />
          <div className="size-2 rounded-full bg-gray-700 animate-shimmer" />
        </div>

        {children}
      </div>
    </div>
  );
}
Notifications.propTypes = {
  children: PropTypes.node,
};

export default Notifications;
