import Lottie from 'react-lottie';
import Loading from '../lotties/loading.json';

const Spinner = () => {
  return (
    <Lottie
      options={{
        loop: true,
        autoplay: true,
        animationData: Loading,
      }}
    />
  );
};

export default Spinner;
