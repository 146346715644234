import React, { useState } from 'react';
import useSWR from 'swr';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RxChevronLeft } from 'react-icons/rx';
import { ReactSVG } from 'react-svg';
import { serviceInstance } from '../../../lib/axios';
import useUser from '../../../hooks/useUser';
import DashboardLayout from '../../../components/layout/dashboardLayout';
import Spinner from '../../../components/loading';

const AnalyticsDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, _] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const { user, selectedProfile } = useUser();

  const { data: details, isValidating } = useSWR(
    user?.api_key && selectedProfile?.time_zone
      ? `/singleservice_status_initial`
      : null,
    async (key) => {
      const response = await serviceInstance.post(
        key,
        {
          id_cliente: selectedProfile?.id_cliente,
          timezone: selectedProfile?.time_zone,
          id_test: id,
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );
      return response.data.transaction_initial_status_data;
    },
  );

  return (
    <DashboardLayout>
      <div className="flex items-center justify-between mb-8">
        <div className="flex flex-row gap-2">
          <button
            className="flex flex-row items-center gap-2 text-white"
            onClick={() => navigate(-1)}
            tabIndex="0"
          >
            <RxChevronLeft className="size-6 text-4xl" />
          </button>
          <div>
            <h1 className="text-white text-3xl font-bold">
              {searchParams.get('name')}
            </h1>
          </div>
        </div>
        <div className="flex items-center">
          <div className="w-8 h-8 bg-gray-700 rounded-full flex items-center justify-center">
            <ReactSVG
              src={`/svgs/${parseInt(searchParams.get('monitor_family'), 10) === 1 ? 'robot' : 'privalytics'}.svg`}
            />
          </div>
        </div>
      </div>

      <div className="space-y-4 text-white">
        {details?.length && !isValidating ? (
          details.map((detail, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="w-full bg-gray-800 rounded-lg p-4 flex justify-between items-center text-left focus:outline-none"
              >
                <span className="text-lg font-semibold">
                  Reglas de monitoreo
                </span>
                <svg
                  className={`w-5 h-5 transform transition-transform ${
                    isOpen ? 'rotate-180' : ''
                  } text-gray-400`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              {/* Contenido del desplegable */}
              {isOpen && (
                <table className="table-auto bg-gray-800 rounded-lg p-4 space-y-2 text-center w-full">
                  <tbody>
                    <tr>
                      <td className="border border-gray-500 px-4 py-2 text-start">
                        Tiempo de evaluación
                      </td>
                      <td className="font-semibold border border-gray-500 px-4 py-2 text-end">
                        {detail.evalwinmin} min
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-500 px-4 py-2 text-start">
                        Mínimo a evaluar
                      </td>
                      <td className="font-semibold border border-gray-500 px-4 py-2 text-end">
                        {detail.min_eval_samples} tx
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-500 px-4 py-2 text-start">
                        Observadas
                      </td>
                      <td className="font-semibold border border-gray-500 px-4 py-2 text-end">
                        {detail.numtxcompleted} tx (
                        {parseFloat(
                          detail.min_eval_samples / detail.evalwinmin,
                        ).toFixed(2)}{' '}
                        tpm)
                      </td>
                    </tr>
                    <tr>
                      <td className="border borders-gray-500 px-4 py-2 text-start">
                        Máximo errores
                      </td>
                      <td className="font-semibold border border-gray-500 px-4 py-2 text-end">
                        {detail.umbral_error}%
                      </td>
                    </tr>
                    <tr>
                      <td className="border border-gray-500 px-4 py-2 text-start">
                        Máximo expiradas
                      </td>
                      <td className="font-semibold border border-gray-500 px-4 py-2 text-end">
                        {detail.umbral_expiradas}%
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}

              {/* Indicadores */}
              <div className="space-y-2 text-white mt-2">
                <div className="bg-gray-800 rounded-lg p-4 flex justify-between items-center">
                  <div className="flex flex-row gap-4 items-center">
                    <span className="text-green-400 text-2xl">
                      <ReactSVG src="/svgs/thumbUp.svg" />
                    </span>
                    <span className="font-bold">Correctas</span>
                  </div>
                  <span className="font-semibold">
                    {detail.numtxok} tx (
                    {parseFloat(detail.numtxok / detail.evalwinmin).toFixed(2)})
                  </span>
                </div>

                <div className="bg-gray-800 rounded-lg p-4 flex justify-between items-center">
                  <div className="flex flex-row gap-4 items-center">
                    <span className="text-green-400 text-2xl">
                      <ReactSVG src="/svgs/phantom.svg" />
                    </span>
                    <span className="font-bold">Expiradas</span>
                  </div>
                  <span className="font-semibold">
                    {detail.numtxexpired} ({detail.umbral_expiradas})%
                  </span>
                </div>

                <div className="bg-gray-800 rounded-lg p-4 flex justify-between items-center">
                  <div className="flex flex-row gap-4 items-center">
                    <span className="text-green-400 text-2xl">
                      <ReactSVG src="/svgs/warning.svg" />
                    </span>
                    <span className="font-bold">Error</span>
                  </div>
                  <span className="font-semibold">
                    {detail.numtxfail} ({detail.umbral_error})%
                  </span>
                </div>

                <div className="bg-gray-800 rounded-lg p-4 flex justify-between items-center">
                  <div className="flex flex-row gap-4 items-center">
                    <span className="text-green-400 text-2xl">
                      <ReactSVG src="/svgs/running.svg" />
                    </span>
                    <span className="font-bold">Activas</span>
                  </div>
                  <span className="font-semibold">{detail.numtxrun}</span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <Spinner />
        )}
      </div>
    </DashboardLayout>
  );
};

export default AnalyticsDetails;
