import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocalStorage } from '@uidotdev/usehooks';
import toast from 'react-hot-toast';
import { onMessage } from 'firebase/messaging';
import { useNavigate } from 'react-router-dom';
import { messaging } from '../lib/firebase';
import useUser from '../hooks/useUser';

function ProtectedRoute({ children }) {
  const { user, selectedProfile } = useUser();
  const [value, setValue] = useLocalStorage('showNotification', 'false');
  const navigate = useNavigate();

  useEffect(() => {
    const handleMessage = async () => {
      const messagingResolve = await messaging;
      onMessage(messagingResolve, (payload) => {
        toast.success(payload.notification.body);
        setValue('true');
      });
    };

    handleMessage();
  }, []);

  if (!user.api_key) {
    return navigate('/login');
  }

  return children;
}

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
