import React, { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import './styles.css';
import { getToken } from 'firebase/messaging';
import { PiArrowRight, PiArrowLeft } from 'react-icons/pi';
import { useNavigate } from 'react-router-dom';
import { Scanner } from '@yudiel/react-qr-scanner';
import { serviceInstance } from '../../lib/axios';
import { generateToken, messaging } from '../../lib/firebase';

function ScanQR() {
  const navigate = useNavigate();
  const [token, setToken] = React.useState('');

  const [isScanning, setIsScanning] = React.useState(false);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await generateToken();
      setToken(token);
    };
    fetchToken();
  }, []);

  if (isScanning) {
    return (
      <div className="h-full flex flex-col qr-scanner">
        <Scanner
          formats={[
            'qr_code',
            'micro_qr_code',
            'rm_qr_code',
            'maxi_code',
            'pdf417',
            'aztec',
            'data_matrix',
            'matrix_codes',
            'dx_film_edge',
            'databar',
            'databar_expanded',
            'codabar',
            'code_39',
            'code_93',
            'code_128',
            'ean_8',
            'ean_13',
            'itf',
            'linear_codes',
            'upc_a',
            'upc_e',
          ]}
          onScan={async (value) => {
            const url = new URL(value[0]?.rawValue);
            const apiKey = url.searchParams.get('api_key');
            if (apiKey) {
              const userInfo = await serviceInstance.post(
                '/user_info',
                {},
                {
                  headers: {
                    Authorization: apiKey,
                  },
                },
              );
              localStorage.setItem('firebase-token', token);

              await serviceInstance.post(
                '/register_device',
                {
                  id_cliente: userInfo.data.data[0].id_cliente,
                  token,
                  device_name: navigator.userAgent,
                },
                {
                  headers: {
                    Authorization: apiKey,
                  },
                },
              );

              localStorage.setItem(
                'user',
                JSON.stringify({
                  api_key: apiKey,
                }),
              );
              navigate('/dashboard');
            }
          }}
          components={{
            torch: false,
          }}
          scanDelay={2000}
        />
      </div>
    );
  }
  return (
    <div className="bg-[#0C1116] min-h-screen flex flex-col">
      <button
        className="flex flex-row items-center gap-2 text-white ml-8 my-8"
        onClick={() => navigate(-1)}
        tabIndex="0"
      >
        <PiArrowLeft className="size-6 text-4xl" />
        <p>Volver</p>
      </button>
      <div className="flex flex-col gap-4 h-full w-full items-center justify-center mt-auto mb-10">
        <ReactSVG src="illustrations/QR.svg" />
      </div>
      <div className="flex flex-col gap-10 bg-[#131B23] border-red-100 w-full px-8 py-14 rounded-3xl">
        <div className="flex flex-col gap-4 w-full">
          <h1 className="text-4xl font-bold text-white">Escanea el QR</h1>
          <p className="text-white font-medium">
            Para continuar, escanea el código QR que se encuentra en la
            plataforma web para accede a la aplicación de realUptîme. ¡Es rápido
            y seguro!
          </p>
        </div>

        <div className="flex flex-col gap-6">
          <div className="flex flex-row gap-4">
            <button
              className="flex flex-row justify-between items-center bg-[#449670] p-5 rounded-2xl text-white w-full text-left font-semibold text-lg"
              onClick={() => setIsScanning(true)}
            >
              Continuar
              <PiArrowRight className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScanQR;
