import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import useSWR from 'swr';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PiExclamationMark } from 'react-icons/pi';
import { RiCloseLine } from 'react-icons/ri';
import { RxChevronLeft, RxChevronRight } from 'react-icons/rx';
import Modal from 'react-modal';
import classNames from 'classnames';
import { serviceInstance } from '../../../lib/axios';
import useUser from '../../../hooks/useUser';
import DashboardLayout from '../../../components/layout/dashboardLayout';
import useLocationConfig from '../../../hooks/useLocationConfig';
import Spinner from '../../../components/loading';

function PngThumb({ encodedPngname, friendlyPngname }) {
  if (encodedPngname) {
    return (
      <div className="w-full">
        <a
          href={`https://storage.googleapis.com/realuptime-screenshots/secure_png/${
            encodedPngname
          }`}
          target="_blank"
          className="w-full"
          rel="noopener noreferrer"
        >
          <img
            src={`https://storage.googleapis.com/realuptime-screenshots/secure_png/thumb_${
              encodedPngname
            }`}
            className="w-[100%] w3-padding"
            alt={friendlyPngname}
          />
          <div className="text-white">{friendlyPngname}</div>
        </a>
      </div>
    );
  }
}

PngThumb.propTypes = {
  encodedPngname: PropTypes.string,
  friendlyPngname: PropTypes.string,
};

Modal.setAppElement('#root');
const IncidentStatusOverview = () => {
  const navigate = useNavigate();
  const configData = useLocationConfig();
  const [selectedB64Info, setSelectedB64Info] = React.useState(false);
  const [details, setDetails] = React.useState([]);
  const { id } = useParams();
  const [searchParams, _] = useSearchParams();
  const { user, selectedProfile } = useUser();

  const getSingleServiceStatusInitial = async () => {
    const response = await serviceInstance.post(
      '/singleservice_status_initial',
      {
        id_cliente: selectedProfile?.id_cliente,
        timezone: selectedProfile?.time_zone,
        id_test: id,
      },
      {
        headers: {
          Authorization: user.api_key,
        },
      },
    );

    setDetails(response.data?.initial_status_data);
  };

  useEffect(() => {
    if (user?.api_key && selectedProfile?.time_zone) {
      getSingleServiceStatusInitial();
    }
  }, [selectedProfile]);

  const {
    data: singleServiceLocation,
    mutate,
    isValidating,
  } = useSWR(
    user?.api_key && selectedProfile?.time_zone
      ? `/singleservice_status_location`
      : null,
    async (key) => {
      const response = await serviceInstance.post(
        key,
        {
          id_cliente: selectedProfile?.id_cliente,
          timezone: selectedProfile?.time_zone,
          id_test: id,
          id_ubicacion: parseInt(searchParams.get('id'), 10),
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );
      return response.data;
    },
  );

  useEffect(() => {
    mutate();
  }, []);

  function openModal(id) {
    setSelectedB64Info(id);
  }

  function closeModal() {
    setSelectedB64Info(false);
  }

  const filteredByIdUbicacion =
    details &&
    details.filter(
      (detail) => detail.id_ubicacion === parseInt(searchParams.get('id'), 10),
    );

  const filteredConfig = useMemo(
    () => (index) => singleServiceLocation?.response_status_data?.at(index),
    [singleServiceLocation],
  );

  return (
    <DashboardLayout>
      <div className="flex items-center justify-between mb-8">
        <div className="flex flex-row gap-2">
          <button
            className="flex flex-row items-center gap-2 text-white"
            onClick={() => navigate(-1)}
            tabIndex="0"
          >
            <RxChevronLeft className="size-6 text-4xl" />
          </button>
          <div className="text-white">
            <h1 className="text-3xl font-bold">{searchParams.get('name')}</h1>
            <span>{searchParams.get('prev')}</span>
          </div>
        </div>
        <div className="flex items-center">
          <div className="bg-gray-700 rounded-full flex items-center justify-center">
            <img
              src={`https://api.realuptime.net/flags/${searchParams.get('country')}.png`}
              alt="flag"
              className="h-8 w-12"
            />
          </div>
        </div>
      </div>

      <div className="space-y-4 h-[76vh] overflow-y-auto">
        {filteredConfig && !isValidating ? (
          filteredByIdUbicacion?.map((detail, index) => (
            <button
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={() =>
                singleServiceLocation?.metric_status_data?.length > 0 &&
                navigate(
                  `incident-breakdown?index=${index}&id=${searchParams.get('id')}&prev=${searchParams.get('name')}&country=${searchParams.get('country')}`,
                )
              }
              className={classNames(
                'bg-gray-800 w-full p-4 rounded-lg flex justify-between items-center border-l-4 border-green-500',
                {
                  'border-red-500': filteredConfig(index)?.success === 'false',
                },
              )}
            >
              <div className="flex flex-row justify-between items-center w-full">
                <div className="flex flex-col gap-2 w-full">
                  <div className="flex flex-row gap-1">
                    <p className="text-white text-2xl font-bold mb-2 w-[18rem] truncate">
                      {detail?.label}
                    </p>
                    {filteredConfig(index)?.b64_payload && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          openModal(filteredConfig(index)?.b64_payload);
                        }}
                        className="border rounded-full bg-transparent size-4"
                      >
                        <PiExclamationMark color="white" />
                      </button>
                    )}
                  </div>

                  <div>
                    <div className="flex flex-row gap-2">
                      <PngThumb
                        encodedPngname={
                          singleServiceLocation?.png_status_data?.at(index)
                            ?.encoded_pngname
                        }
                        friendlyPngname={
                          singleServiceLocation?.png_status_data?.at(index)
                            ?.png_event
                        }
                      />
                    </div>
                  </div>

                  <div className="flex flex-row gap-2 text-gray-400 text-sm w-[15rem] truncate">
                    <p>{detail.responsemessage}</p>
                  </div>
                  <div className="flex flex-row gap-2 text-gray-400 text-sm w-[15rem] truncate">
                    <p>{detail.timestamp}</p>
                  </div>
                </div>

                {singleServiceLocation?.metric_status_data?.length > 0 && (
                  <button className="rounded-full bg-transparent">
                    <RxChevronRight color="gray" size={36} />
                  </button>
                )}
              </div>
            </button>
          ))
        ) : (
          <Spinner />
        )}
      </div>

      <Modal
        isOpen={selectedB64Info}
        onRequestClose={() => closeModal()}
        style={{
          content: {
            position: 'relative',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            width: '90%',

            paddingLeft: '1rem',
            paddingRight: '1rem',
            backgroundColor: '#1a202c',
            color: 'white',
            border: 'none',
            transform: 'translate(-50%, -50%)',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <div className="absolute right-0 top-0 p-2">
          <button
            onClick={() => {
              closeModal();
            }}
            className="border rounded-full bg-gray-700 size-4 mt-2"
          >
            <RiCloseLine color="white" />
          </button>
        </div>
        <h2 className="pb-2 border-b">Más Información</h2>
        <div className="flex break-all pt-2 h-[15rem] overflow-y-auto">
          {selectedB64Info && atob(selectedB64Info)}
        </div>
      </Modal>
    </DashboardLayout>
  );
};

export default IncidentStatusOverview;
