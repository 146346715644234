import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';

import useSWR from 'swr';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { es } from 'date-fns/locale';
import { RxChevronLeft } from 'react-icons/rx';
import { formatDistance } from 'date-fns';
import useUser from '../../../hooks/useUser';
import { serviceInstance } from '../../../lib/axios';
import DashboardLayout from '../../../components/layout/dashboardLayout';
import Spinner from '../../../components/loading';

const timesInvertal = [6, 12, 24, 72];

export default function IncidentesBreakdownView() {
  const navigate = useNavigate();
  const { user, setSelectedProfile, selectedProfile } = useUser();
  const [selectedInterval, setSelectedInterval] = useState(72);
  const [openFilter, setOpenFilter] = useState(false);

  const [searchParams, _] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [openFirst, setOpenFirst] = useState(true);
  const [openSecond, setOpenSecond] = useState(true);
  const { id } = useParams();
  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    setSelectedProfile(option);
    setIsOpen(false);
  };

  const {
    data: incidents,
    mutate,
    isValidating,
  } = useSWR(
    user?.api_key && selectedProfile?.time_zone ? `/incidents_interval` : null,
    async (key) => {
      const response = await serviceInstance.post(
        key,
        {
          id_test: id,
          id_cliente: 0,
          interval_end: searchParams.get('interval_end'),
          interval_start: searchParams.get('interval_start'),
          timezone: 'America/Santiago',
        },
        {
          headers: {
            Authorization: user.api_key,
          },
        },
      );

      return response.data.data;
    },
  );

  const selectedIncident = incidents?.find(
    (incident) => incident.id_test === parseInt(id, 10),
  );

  return (
    <DashboardLayout>
      <div>
        <div className="flex items-center justify-between mb-8">
          <div className="flex flex-row gap-2">
            <button
              className="flex flex-row items-center gap-2 text-white"
              onClick={() => navigate(-1)}
              tabIndex="0"
            >
              <RxChevronLeft className="size-6 text-4xl" />
            </button>
            <div className="text-white">
              <h1 className="text-3xl font-bold">{searchParams.get('name')}</h1>
              <span>Detalles</span>
            </div>
          </div>
          <div className="flex items-center">
            <div className="min-h-12 min-w-12 max-w-12 max-h-12 bg-gray-700 rounded-full flex items-center justify-center">
              <ReactSVG
                src={`/svgs/${searchParams.get('displayLogo')}.svg`}
                alt="icono"
              />
            </div>
          </div>
        </div>

        {selectedIncident && !isValidating ? (
          <div className="space-y-4 h-[55vh] overflow-y-auto p-2 mt-8">
            <button
              onClick={() => setOpenFirst(!openFirst)}
              className="w-full bg-[#162029] rounded-lg p-4 flex justify-between items-center text-left focus:outline-none text-white"
            >
              <span className="text-lg font-semibold">
                {selectedIncident?.nombre}
              </span>
              <svg
                className={`w-5 h-5 transform transition-transform ${
                  isOpen ? 'rotate-180' : ''
                } text-gray-400`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>

            {openFirst && (
              <div className="bg-gray-800 rounded-lg p-4 space-y-2 text-center text-white">
                <div className="flex justify-between">
                  <span>Fecha de apertura</span>
                  <span className="font-semibold text-green-600">
                    {selectedIncident.inicio}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Fecha de cierre</span>
                  <span className="font-semibold text-red-600">
                    {selectedIncident.fin}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Duración</span>
                  <span className="font-semibold">
                    {formatDistance(
                      new Date(selectedIncident.inicio),
                      new Date(selectedIncident.fin ?? new Date()),
                      {
                        addSuffix: true,
                        locale: es,
                      },
                    ).replace('hace', '')}
                  </span>
                </div>
              </div>
            )}

            <button
              onClick={() => setOpenSecond(!openSecond)}
              className="w-full bg-[#162029] rounded-lg p-4 text-white flex justify-between items-center text-left focus:outline-none"
            >
              <span className="text-lg font-semibold">
                Incidente confirmado
              </span>
              <svg
                className={`w-5 h-5 transform transition-transform ${
                  isOpen ? 'rotate-180' : ''
                } text-gray-400`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>

            {openSecond && (
              <div className="bg-gray-800 rounded-lg p-4 space-y-2 text-center text-white">
                <div className="flex justify-between">
                  <span>Correo de confirmación</span>
                  <span className="font-semibold text-gray-400">
                    {selectedIncident.email_user_ack ?? 'No'}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span>Fecha de confirmación</span>
                  <span className="font-semibold text-gray-400">
                    {selectedIncident.timestamp_user_ack ?? 'No'}
                  </span>
                </div>
              </div>
            )}
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </DashboardLayout>
  );
}
